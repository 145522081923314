import { IconPropType } from '@/ui/Icon';

export enum LearningPartner {
  PLURALSIGHT = 'pluralsight',
  COLLEGIAL = 'collegial',
  COURSERA = 'coursera',
  LINKEDIN = 'linkedin',
  FINANSKOMPETENS = 'finanskompetens',
  LEARNIFIER = 'learnifier',
  IMPARTA = 'imparta',
}

export const LEARNING_PARTNER_OPTIONS = [
  { value: LearningPartner.COLLEGIAL, displayValue: 'Collegial' },
  { value: LearningPartner.COURSERA, displayValue: 'Coursera' },
  { value: LearningPartner.FINANSKOMPETENS, displayValue: 'Finanskompetens' },
  { value: LearningPartner.LINKEDIN, displayValue: 'LinkedIn Learning' },
  { value: LearningPartner.LEARNIFIER, displayValue: 'Learnifier' },
  { value: LearningPartner.IMPARTA, displayValue: 'Imparta' },
  { value: LearningPartner.PLURALSIGHT, displayValue: 'Pluralsight' },
];

// TODO (for later) Imparta partner icon missing
export const PARTNER_ICON_MAP: Partial<Record<LearningPartner, IconPropType | null>> = {
  pluralsight: 'c-pluralsight-square',
  collegial: 'c-collegial-square',
  coursera: 'c-coursera-square',
  learnifier: 'c-collegial-square', // learnifier intentionally uses the collegial logo.
  linkedin: 'c-linkedin-square',
  finanskompetens: 'c-finanskompetens-square',
};

export const LEARNING_TYPES_OPTIONS = [
  { value: 'article', displayValue: 'Article', prefixIcon: ['far', 'memo'] },
  { value: 'audio', displayValue: 'Audio', prefixIcon: ['far', 'microphone'] },
  { value: 'video', displayValue: 'Video', prefixIcon: ['far', 'video'] },
  { value: 'seminar', displayValue: 'Seminar', prefixIcon: ['far', 'presentation'] },
  { value: 'resource', displayValue: 'Resource', prefixIcon: ['far', 'paperclip-vertical'] },
  { value: 'webinar', displayValue: 'Webinar', prefixIcon: ['far', 'camera-web'] },
  { value: 'eLearning', displayValue: 'E-learning', prefixIcon: ['far', 'tv'] },
  {
    value: 'onlineLearning',
    displayValue: 'On-demand learning',
    prefixIcon: ['far', 'computer-mouse'],
  },
  { value: 'course', displayValue: 'Course', prefixIcon: ['far', 'road'] },
  { value: 'path', displayValue: 'Path', prefixIcon: ['far', 'road'] },
];

export const CATALOG_TABLE_ACTIONS_SELECT_BREAKPOINT = 1657;
