import { ReactNode } from 'react';
import styled from 'styled-components';

import { ContentSection } from '@/ui/Layout/ContentSection';

type PageProps = {
  children: ReactNode;
  noPadding?: boolean;
};

const RootStyled = styled.div<{ noPadding: boolean }>`
  padding: ${({ noPadding }) => (noPadding ? '0' : '64px 0')};
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const HeaderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Page = ({ children, noPadding = false }: PageProps): JSX.Element => {
  return (
    <ContentSection>
      <RootStyled noPadding={noPadding}>{children}</RootStyled>
    </ContentSection>
  );
};
